import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { isNull } from "lodash"
import Seo from "components/Seo"
import PageHeader from "components/PageHeader"
import CaseCard from "components/CaseCard"
import Pagination from "components/Pagination"
import CaseLayout from "components/cases/CaseLayout"

export default function CaseArchive({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) {
  const posts = data.allWpCase.nodes
  const caseCategories = data.allWpCaseCategory.nodes

  return (
    <>
      <CaseLayout
        title={"Case Studies"}
        description="함께한 HPC컨설팅 고객사의 컨설팅 사례를 찾아보세요."
      >
        <div className="w-full lg:container mx-auto px-4 lg:px-8">
          <div className="flex flex-row flex-wrap -mx-4">
            {!posts.length ? (
              <div>게시글이 없습니다.</div>
            ) : (
              posts.map(item => {
                console.log(item)
                return (
                  <div
                    className="px-4 w-full lg:w-6/12 xl:w-4/12 mb-8"
                    key={item.id}
                  >
                    <CaseCard
                      url={item.uri}
                      title={item.title}
                      eyebrow={
                        item?.caseCategories && (
                          <ul>
                            {item.caseCategories.nodes.map(
                              (item, index) =>
                                !index && (
                                  <li key={item.id} className="text-gray-500">
                                    {item.name}
                                  </li>
                                )
                            )}
                          </ul>
                        )
                      }
                      featuredImage={
                        <div className="relative overflow-hidden">
                          <div
                            className="bg-gray-200"
                            style={{ paddingBottom: "56.25%" }}
                          ></div>
                          <GatsbyImage
                            style={{
                              position: "absolute",
                              height: "100%",
                              width: "100%",
                              inset: 0,
                              objectFit: "cover",
                            }}
                            image={
                              item.featuredImage?.node?.localFile
                                ?.childImageSharp?.gatsbyImageData
                            }
                            alt={item.featuredImage?.node?.altText || ``}
                          />
                        </div>
                      }
                      consultant={item.case.consultant}
                    />
                  </div>
                )
              })
            )}
          </div>
          <Pagination
            previousPagePath={previousPagePath}
            nextPagePath={nextPagePath}
          />
        </div>
      </CaseLayout>
    </>
  )
}

export const pageQuery = graphql`
  query WordPressCaseArchive($offset: Int!, $postsPerPage: Int!) {
    allWpCaseCategory {
      nodes {
        id
        slug
        uri
        name
        count
      }
    }
    allWpCase(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        id
        date
        slug
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        caseCategories {
          nodes {
            id
            name
            slug
            uri
          }
        }
        case {
          consultant {
            ... on WpConsultant {
              id
              slug
              uri
              title
              consultant {
                role
              }
              featuredImage {
                node {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
            }
          }
        }
        uri
      }
    }
  }
`
